import { defineStore } from 'pinia'
import { useCartStore } from '@/Stores/Cart.js'

export const useCartBaggagesStore = defineStore('CartBaggages', () => {
    const data = ref({})

    const loadData = async () => {
        await axios.get('/api/cart/baggage').then((response) => {
            data.value = response.data
        })
    }

    const updateQty = (payload) => {
        return axios.post('/api/cart/baggage', payload).then((response) => {
            useCartStore().reload()
        })
    }

    return {
        data,
        loadData,
        updateQty
    }
})
