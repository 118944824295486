import './bootstrap'
import '../css/app.css'

import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { UtilitiesVue } from './Helpers/Utilities'
import i18n from './Services/Lang.js'
import Search from './Views/Search.vue'
import Offers from './Views/Offers.vue'
import Offer from './Views/Offer.vue'
import CartOffer from './Views/CartOffer.vue'
import CartBaggage from './Views/CartBaggage.vue'
import CartTransfer from './Views/CartTransfer.vue'
import CartTrips from './Views/CartTrips.vue'
import Checkout from './Views/Checkout.vue'
import Payment from './Views/Payment.vue'
import PaymentResponse from './Views/PaymentResponse.vue'
import Booking from './Views/Booking.vue'
import CheckoutSuccess from './Views/CheckoutSuccess.vue'
import Wishlist from './Views/Wishlist.vue'
import Newsletter from './Views/Newsletter.vue'
import Trips from './Views/Trips.vue'
import SkeletonTrips from './Components/SkeletonTrips.vue'
import WhishListCount from './Components/WhishListCount.vue'
import Calendar from './Components/Global/Calendar.vue'
import Loading from './Components/Global/Loading.vue'
import ContactForm from './Components/Snippet/ContactForm.vue'
import router from './Routes/index.js'
import { createPinia } from 'pinia'

const app = createApp({
    components: {
        Calendar,
        Search,
        Offers,
        Offer,
        CartOffer,
        CartTransfer,
        CartBaggage,
        CartTrips,
        Checkout,
        CheckoutSuccess,
        Payment,
        PaymentResponse,
        Booking,
        Wishlist,
        Newsletter,
        WhishListCount,
        Trips,
        SkeletonTrips,
        Loading,
        ContactForm
    },
    delimiters: ['${', '}']
})
    .use(i18n)
    .use(createPinia())
    .use(router)
    .use(UtilitiesVue)
app.mount('#app')

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
