import { defineStore } from 'pinia'

export const useCartStore = defineStore('Cart', () => {
    const cart = ref({})

    const currentStep = ref({})

    const processing = ref(false)

    const router = useRouter()

    const loadData = () => {
        return axios
            .get('/api/cart')
            .then((response) => {
                cart.value = response.data
                return response.data
            })
            .catch((error) => {
                throw error
            })
    }

    const reload = () => {
        processing.value = true
        loadData().then(() => {
            processing.value = false
        })
    }

    const updateItem = (hash, qty) => {
        processing.value = true
        return axios
            .put('/api/cart/' + hash, { qty: qty })
            .then((response) => {
                cart.value = response.data
            })
            .finally(() => {
                processing.value = false
            })
    }

    const removeItem = (hash) => {
        processing.value = true
        return axios
            .delete('/api/cart/' + hash)
            .then((response) => {
                cart.value = response.data
            })
            .finally(() => {
                processing.value = false
            })
    }

    const steps = computed(() => {
        return cart.value.steps ?? []
    })

    const nextStep = computed(() => {
        const index = steps.value.findIndex((step) => step === currentStep.value)

        return steps.value[index + 1] ?? 'checkout'
    })

    const itemOffer = computed(() => {
        return items.value.find((item) => item.product == 'offer')
    })

    const itemTransfer = computed(() => {
        return items.value.find((item) => item.product == 'transfer')
    })

    const itemInsurance = computed(() => {
        return items.value.find((item) => item.id == 'insurance_cancellation_risk')
    })

    const itemPriority = computed(() => {
        return items.value.find((item) => item.id == 'priority')
    })

    const full_payment_amount = computed(() => {
        return cart.value.full_payment_amount ?? 0
    })

    const total = computed(() => {
        return cart.value.total ?? 0
    })

    const full_payment_at = computed(() => {
        return cart.value.full_payment_at ?? null
    })

    const reservation_amount = computed(() => {
        return cart.value.reservation_amount ?? 0
    })

    const items = computed(() => {
        return Object.values(cart.value.items ?? {})
    })

    const count = computed(() => {
        return items.value.length
    })

    const prevStep = computed(() => {
        const index = steps.value.findIndex((step) => step === current)
        return steps.value[index - 1] ?? null
    })

    const goToNextStep = () => {
        goToStep(nextStep.value)
    }

    const goToPrevStep = () => {
        goToStep(prevStep.value)
    }

    const goToStep = async (step = null) => {
        if (step == null) return

        let props = router.resolve({
            name: step
        })
        window.location = props.href
    }

    return {
        processing,
        cart,
        currentStep,
        itemOffer,
        itemTransfer,
        itemInsurance,
        itemPriority,
        items,
        full_payment_amount,
        full_payment_at,
        reservation_amount,
        steps,
        total,
        count,
        reload,
        loadData,
        updateItem,
        removeItem,
        goToStep,
        goToNextStep,
        goToPrevStep
    }
})
